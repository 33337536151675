export const ESCharacterBuilds = [
  {
    ID: 1,
    Character: 'Adrianne',
    Slug: 'adrianne',
    Race: 'Angel',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD > HP > EVA',
    Keepsake_Raid: '-',
    Keepsake_PVP: 'HP = SPD = EVA',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Any applies to Hook role. Eternal+/Origin recommended if using as pure tank.',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 2,
    Character: 'Aira',
    Slug: 'aira',
    Race: 'Beast',
    Class: 'Warrior',
    Attribute: 'STR',
    Keepsake_PVE: '-',
    Keepsake_Raid: 'SPD > HP',
    Keepsake_PVP: '-',
    Keepsake_Comment: 'Sole role is to get hit and drop Rune',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Positioning Required. Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Mana Recovery Reduction strong for mana specific raid (Gaiah, ESS).'
  },
  {
    ID: 3,
    Character: 'Aki',
    Slug: 'aki',
    Race: 'Human',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG > ATK',
    Keepsake_Raid: 'CDMG > ATK',
    Keepsake_PVP: 'CDMG/ SPD > ATK',
    Keepsake_Comment: 'First to use Main wins in PvP (usually).',
    Best_Potential_Stats: 'CDMG% > ATK% > Racist Dmg',
    Alternative_Potential_Stats: 'Any Racist Dmg % (excluding Chaotic)',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment:
      'Any damage that helps guarantee kill with main/ ult is good',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Starter Banner accessible, 1 of 3 recommended options for starter carry.',
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment:
      'Easily upgraded thanks to Tower. Stat stick for most part, speed is always relevant for PvE situations'
  },
  {
    ID: 4,
    Character: 'Ayame',
    Slug: 'ayame',
    Race: 'Demon',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD > CDMG > ATK',
    Keepsake_Raid: 'SPD > 2x CDMG + 2x SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: 'SPD as debuffer, CDMG/ATK as DPS',
    Best_Potential_Stats: 'HP, Resistances',
    Alternative_Potential_Stats: 'CDMG% > CR % > Angel DMG%',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Min Rec: applies to Support Role.  Prioritise HP/ Resistances/ Tanky Keepsakes if dying.',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 5,
    Character: 'Beleth',
    Slug: 'beleth',
    Race: 'Human',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD > HP',
    Keepsake_Raid: 'SPD > HP',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '-',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'ATK%',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '-',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment: 'Generally improves heals.'
  },
  {
    ID: 6,
    Character: 'Bryce',
    Slug: 'bryce',
    Race: 'Undead',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'Tank: HP > DEF',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: 'May want Anni ATK set for additional CDMG/Lifesteal',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: 'Demon/Fairy/Undead/Beast Racist Damage',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Only upgrade if no other options for Undead DPS. Fallen off in Raid. L+ artifact recommended for the 50% Ult gauge recovery.'
  },
  {
    ID: 7,
    Character: 'Catherine',
    Slug: 'catherine',
    Race: 'Human',
    Class: 'Supporter',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD > HP > EVA',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD > HP',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Positioning Required. Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 8,
    Character: 'Catherine (Radiance)',
    Slug: 'catherine-radiance',
    Race: 'Chaotic',
    Class: 'Warrior',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD = ATK',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'ATK = SPD',
    Keepsake_Comment:
      'SPD as support, ATK as PvE DPS May want Anni ATK set for easy Crit Rate/ lifesteal.',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'ATK%',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: 'Shields are ATK scaling.',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Chaotic soul, very expensive to ascend and grow outside of Pickup banner. Positioning Required. Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment:
      'Generally improves tankiness for raid scenario. Only upgrade if trying to DPS.'
  },
  {
    ID: 9,
    Character: 'Cherrie',
    Slug: 'cherrie',
    Race: 'Human',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: '-',
    Keepsake_Raid: 'SPD = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast/Fairy Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Origin artifact recommended for min-maxing raid scores only.'
  },
  {
    ID: 10,
    Character: 'Chloe',
    Slug: 'chloe',
    Race: 'Fairy',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'HP = DEF = EVA',
    Keepsake_Raid: '-',
    Keepsake_PVP: 'HP = DEF = EVA',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Can be used at low ascension early on to tank, will likely get stat checked.',
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment:
      'Tower Accessible, easily upgraded.  Power spike at L+, giving free HP recovery.'
  },
  {
    ID: 11,
    Character: 'Claire',
    Slug: 'claire',
    Race: 'Human',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'HP = DEF = EVA',
    Keepsake_Raid: '-',
    Keepsake_PVP: 'HP = DEF = EVA',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Can be used at low ascension early on to tank, will likely get stat checked.',
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment:
      'Tower Accessible, easily upgraded.  Power spike at L+, giving 3 second immunity on Main.'
  },
  {
    ID: 12,
    Character: 'Clara',
    Slug: 'clara',
    Race: 'Beast',
    Class: 'Supporter',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD > HP',
    Keepsake_Raid: 'SPD > HP',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Positioning Required. Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: 'L+ unlocks cleanse on Main/ Ult'
  },
  {
    ID: 13,
    Character: 'Claudia',
    Slug: 'claudia',
    Race: 'Angel',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Demon Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: 'Currently fallen off from DK raid. Tower Accessible.'
  },
  {
    ID: 14,
    Character: 'Daphne',
    Slug: 'daphne',
    Race: 'Fairy',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD > HP',
    Keepsake_Raid: 'SPD > HP',
    Keepsake_PVP: 'SPD > HP',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      "Levels/ Ascension recommended if Immortality procc'ed too fast.",
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment:
      "Higher arti ascension can increase her survivability, but it's not necessary in most cases."
  },
  {
    ID: 15,
    Character: 'Dominique',
    Slug: 'dominique',
    Race: 'Fairy',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD = CDMG = ATK',
    Keepsake_Raid: 'SPD = CDMG = ATK',
    Keepsake_PVP: 'SPD = CDMG = ATK',
    Keepsake_Comment:
      'SPD as support. May want Anni ATK set 3rd/ 4th slot for lifesteal.',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Min Rec: applies to Support Role.  Prioritise HP/ Resistances/ Tanky Keepsakes if dying.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment:
      'Mainly improves personal DPS. Role is now by default Debuffer.'
  },
  {
    ID: 16,
    Character: 'Dora',
    Slug: 'dora',
    Race: 'Human',
    Class: 'Warrior',
    Attribute: 'STR',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'SPD = CDMG = ATK',
    Keepsake_Comment: 'May want Anni ATK set for easy Crit Rate/ lifesteal.',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Origin artifact recommended for PVP use due to CC Immunity'
  },
  {
    ID: 17,
    Character: 'Edith',
    Slug: 'edith',
    Race: 'Fairy',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Starter Banner accessible, 1 of 3 recommended options for starter carry. Should do especially well in PvP.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment: 'Leg+/ OG art is speed boost. Stat stick otherwise.'
  },
  {
    ID: 18,
    Character: 'Eileen',
    Slug: 'eileen',
    Race: 'Human',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      "Quite squishy. If she dies before she kills anything, she's not doing her job.",
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 19,
    Character: 'Erika',
    Slug: 'erika',
    Race: 'Fairy',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: 'Ice box for balance patch.'
  },
  {
    ID: 20,
    Character: 'Erusha',
    Slug: 'erusha',
    Race: 'Undead',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: 'May want Anni ATK set 3rd/ 4th slot for lifesteal.',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 21,
    Character: 'Eve',
    Slug: 'eve',
    Race: 'Demon',
    Class: 'Defender',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD = ATK',
    Keepsake_Comment: 'SPD as support. ATK if trying to make her carry.',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'ATK% > CDMG',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment:
      'if trying to make her highest attack to carry, will need % ATK. Support Role is much more universal.',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Demon Soul - will need 14 dupes to hit Origin. Can fulfill role as buffer/ mana shred at low ascension but might blow up in the face of too much damage.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment:
      'Generally makes party tankier. Can still function at low level. OG needed for carry mode.'
  },
  {
    ID: 22,
    Character: 'Flynn',
    Slug: 'flynn',
    Race: 'Beast',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'SPD = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 23,
    Character: 'Garnet',
    Slug: 'garnet',
    Race: 'Undead',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'ATK%',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 24,
    Character: 'Haru',
    Slug: 'haru',
    Race: 'Beast',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: '',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: 'HP%, Resistances',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 25,
    Character: 'Hazel',
    Slug: 'hazel',
    Race: 'Human',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'SPD = ATK = CDMG',
    Keepsake_Comment: 'May want Anni ATK set 3rd/ 4th slot for lifesteal.',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 26,
    Character: 'Honglan',
    Slug: 'honglan',
    Race: 'Beast',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: 'Dmg Pots are if you are running her as a DPS carry.',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Min Rec: applies to Support Role.  Prioritise HP/ Resistances/ Tanky Keepsakes if dying.',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Eternal',
    Artifact_Priority: '07 - Specialist (PVE)',
    Artifact_Comment:
      'Tower Accessible. Eternal Artifact unlocks mana shred for sub skills'
  },
  {
    ID: 27,
    Character: 'Honglan (Peerless)',
    Slug: 'honglan-peerless',
    Race: 'Beast',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD = CDMG = ATK',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD/ HP/ DEF',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Min Rec: applies to Debuffer Role. Will need Defensive Support/ Ascension if trying to use as Tank.  Prioritise HP/ Resistances/ Tanky Keepsakes if dying.',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment:
      'Legend Art - Resist Shred capped. Legend+ Art - Normal Attacks Empowered. Eternal Art - unlocks Speed and Dmg Reduction party buff.'
  },
  {
    ID: 28,
    Character: 'Jacqueline',
    Slug: 'jacqueline',
    Race: 'Undead',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG > ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: 'CDMG = ATK > SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      "Really needs the stats to do her job. If she's not killing at least 2 units, she's cannon fodder. Very expensive. If you need a Jacq, borrow one. Will do very well in PvP (offensively) but performance is heavily tied to both ascension and artifact.",
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment: 'Tower Accessible.'
  },
  {
    ID: 29,
    Character: 'Jade',
    Slug: 'jade',
    Race: 'Human',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD = ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: 'Just icebox and hope Nineark has mercy on her.'
  },
  {
    ID: 30,
    Character: 'Jiho',
    Slug: 'jiho',
    Race: 'Human',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '05 - Niche (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 31,
    Character: 'Joanne',
    Slug: 'joanne',
    Race: 'Undead',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'ATK%',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 32,
    Character: 'Kanna',
    Slug: 'kanna',
    Race: 'Fairy',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'SPD = CDMG = ATK',
    Keepsake_Raid: 'SPD = CDMG = ATK',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Origin artifact recommended for min-maxing raid scores only.'
  },
  {
    ID: 33,
    Character: 'Kurumi Tokisaki',
    Slug: 'kurumi-tokisaki',
    Race: 'Chaotic',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Demon Racist Dmg%',
    Alternative_Potential_Stats: 'Any Racist Dmg % (excluding Chaotic)',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Unlikely to rerun at this point of time, do not Erika for their dupes.',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Origin',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '07 - Specialist (PVE)',
    Artifact_Comment: 'Artifact increases alongside Ascension (Collab feature)'
  },
  {
    ID: 34,
    Character: 'Larimar',
    Slug: 'larimar',
    Race: 'Demon',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'Racist Dmg % (ideally 2 lines of each faction)',
    Alternative_Potential_Stats: 'HP%, Resistances',
    Potential_Priority: '09 - Meta (Raids)',
    Potential_Comment:
      'With adjustments to sentence damage in raids being prominent only during ADC specific raids, HP and Resistances with Demon/ Angel damage might be safer to aim for.',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '09 - Meta (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '09 - Meta (Raids)',
    Artifact_Comment: 'Legend+ and Origin increase Mana Shred'
  },
  {
    ID: 35,
    Character: 'Lilith',
    Slug: 'lilith',
    Race: 'Human',
    Class: 'Ranger',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Leg +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment:
      'L+ unlocks Crit Rate Buff. Origin Artifact gives enough mana recovery to get main in 5 actions instead of 6.'
  },
  {
    ID: 36,
    Character: 'Linzy',
    Slug: 'linzy',
    Race: 'Human',
    Class: 'Warrior',
    Attribute: 'DEX',
    Keepsake_PVE: 'EVA',
    Keepsake_Raid: 'CDMG = ATK > EVA',
    Keepsake_PVP: 'EVA',
    Keepsake_Comment:
      'EVA is mainly draw for Linzy in general, but reset heavy minmaxers use CDMG for raid situations.',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: 'Beast Dmg%',
    Potential_Priority: '05 - Niche (Raids)',
    Potential_Comment:
      "Human Dmg% is a possibility if you're using her in DK raid",
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 37,
    Character: 'Linzy (Thanatos)',
    Slug: 'linzy-thanatos',
    Race: 'Chaotic',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK > EVA',
    Keepsake_PVP: 'SPD = CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG%, ATK%, Demon/ Angel Dmg%',
    Alternative_Potential_Stats: 'Any Racist Dmg % (excluding Chaotic)',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Chaotic soul, very expensive to ascend and grow outside of Pickup banner. Will do very well in PvP but performance is heavily tied to both ascension and artifact.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment:
      'Origin Arts gives Linzy maximum Ekstasis buffs at start of Combat.'
  },
  {
    ID: 38,
    Character: 'Lizelotte',
    Slug: 'lizelotte',
    Race: 'Demon',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG > ATK',
    Keepsake_Raid: 'CDMG > ATK',
    Keepsake_PVP: 'SPD = CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG%, ATK% > Angel Dmg%',
    Alternative_Potential_Stats: 'Any Racist Dmg % (excluding Chaotic)',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Demon unit, very expensive to grow. Still very powerful but not a priority nowadays. Will do very well in PvP but performance is heavily tied to both ascension and artifact.',
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment: 'Origin unlocks mana shred on S2 (content specific boon)'
  },
  {
    ID: 39,
    Character: 'Lute',
    Slug: 'lute',
    Race: 'Beast',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'DEF > HP',
    Keepsake_Raid: 'CDMG > DEF',
    Keepsake_PVP: 'DEF > HP',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > Fairy Dmg% > Resistances',
    Alternative_Potential_Stats: 'DEF%',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Origin artifact recommended for min-maxing raid scores only.'
  },
  {
    ID: 40,
    Character: 'Manon',
    Slug: 'manon',
    Race: 'Fairy',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: '-',
    Keepsake_Comment: 'May want Anni ATK set 3rd/ 4th slot for lifesteal.',
    Best_Potential_Stats: 'CDMG% > ATK% > Any Racist Dmg %',
    Alternative_Potential_Stats: 'HP%, Resistances',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 41,
    Character: 'Melfice',
    Slug: 'melfice',
    Race: 'Undead',
    Class: 'Warrior',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'SPD, CDMG, ATK',
    Keepsake_PVP: '',
    Keepsake_Comment: 'RAID: Debuffer = Speed DPS = CDMG/ ATK',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 42,
    Character: 'Mephistopheles',
    Slug: 'mephistopeles',
    Race: 'Human',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment:
      'Can maybe use ATK or CDMG if trying to use her to carry.',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Origin Asc Mephi is free from beginner missions. (Jump Start Missions)',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Tower Accessible. L+ is essential not just for the MRES shred but the pattern improvements.'
  },
  {
    ID: 43,
    Character: 'Mephistopheles (Dawn)',
    Slug: 'mephistopheles-dawn',
    Race: 'Chaotic',
    Class: 'Striker',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'SPD = CDMG = ATK',
    Keepsake_PVP: 'SPD = CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG%, ATK%, Demon/ Angel Dmg%',
    Alternative_Potential_Stats: 'Any Racist Dmg % (excluding Chaotic)',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Leg +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Chaotic soul, very expensive to ascend and grow outside of Pickup banner.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment:
      'Legend+ unlocks "Total Damage received" debuff on Idea Level III'
  },
  {
    ID: 44,
    Character: 'Mica',
    Slug: 'mica',
    Race: 'Beast',
    Class: 'Warrior',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD = CDMG = ATK',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD > ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: 'HP%, Resistances',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Leg +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 45,
    Character: 'Miriam',
    Slug: 'miriam',
    Race: 'Fairy',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 46,
    Character: 'Naiah',
    Slug: 'naiah',
    Race: 'Fairy',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD = CDMG = ATK',
    Keepsake_Raid: 'SPD = CDMG = ATK',
    Keepsake_PVP: 'SPD > ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Any Racist Dmg %',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '07 - Specialist (PVE)',
    Artifact_Comment: 'Tower Accesssible. L+ unlocks Stealth mechanic.'
  },
  {
    ID: 47,
    Character: 'Naomi',
    Slug: 'naomi',
    Race: 'Human',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '05 - Niche (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 48,
    Character: 'Nicole',
    Slug: 'nicole',
    Race: 'Fairy',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'SPD > ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 49,
    Character: 'Nini',
    Slug: 'nini',
    Race: 'Undead',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Min Rec: applies to Support Role.  Prioritise HP/ Resistances/ Tanky Keepsakes if dying.',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 50,
    Character: 'Onyx',
    Slug: 'onyx',
    Race: 'Beast',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment: ''
  },
  {
    ID: 51,
    Character: 'Otoha',
    Slug: 'otoha',
    Race: 'Undead',
    Class: 'Striker',
    Attribute: 'STR',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: 'ATK > CDMG > SPD',
    Keepsake_Comment: 'Ideally wants Anni ATK set',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Starter Banner accessible, 1 of 3 recommended options for starter carry. Will do very well in PvP but performance is heavily tied to both ascension and artifact.',
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Leg +',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '10 - Meta (PVE)',
    Artifact_Comment: ''
  },
  {
    ID: 52,
    Character: 'Petra',
    Slug: 'petra',
    Race: 'Undead',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD, HP',
    Keepsake_Raid: 'SPD, HP',
    Keepsake_PVP: 'HP = DEF = EVA',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '10 - Meta (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Can be used at low ascension early on to tank, will likely get stat checked.',
    Ascension_Priority: '10 - Meta (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 53,
    Character: 'Prim',
    Slug: 'prim',
    Race: 'Undead',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 54,
    Character: 'Rebecca',
    Slug: 'rebecca',
    Race: 'Undead',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 55,
    Character: 'Renee',
    Slug: 'renee',
    Race: 'Fairy',
    Class: 'Striker',
    Attribute: 'STR',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 56,
    Character: 'Rose (Prominence)',
    Slug: 'rose-prominence',
    Race: 'Human',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD = ATK = CDMG',
    Keepsake_Raid: 'SPD = ATK = CDMG',
    Keepsake_PVP: '-',
    Keepsake_Comment:
      'Support Role = SPD DPS = ATK/ CDMG, May want Anni ATK 3rd/ 4th slot for lifesteal.',
    Best_Potential_Stats: 'CDMG% > ATK% > Beast Dmg%',
    Alternative_Potential_Stats: 'HP%, Resistances',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Leg +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment:
      'Origin artifact recommended for min-maxing raid scores only.'
  },
  {
    ID: 57,
    Character: 'Sakuyo',
    Slug: 'sakuyo',
    Race: 'Beast',
    Class: 'Warrior',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '05 - Niche (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 58,
    Character: 'Sakuyo (Inferno)',
    Slug: 'sakuyo-inferno',
    Race: 'Beast',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'SPD > CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 59,
    Character: 'Seeha',
    Slug: 'seeha',
    Race: 'Beast',
    Class: 'Supporter',
    Attribute: 'DEX',
    Keepsake_PVE: 'SPD, HP',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD > ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Leg +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 60,
    Character: 'Sigrid',
    Slug: 'sigrid',
    Race: 'Undead',
    Class: 'Striker',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'SPD, CDMG, ATK',
    Keepsake_PVP: 'SPD = CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment: ''
  },
  {
    ID: 61,
    Character: 'Soonie',
    Slug: 'soonie',
    Race: 'Beast',
    Class: 'Defender',
    Attribute: 'STR',
    Keepsake_PVE: 'SPD, HP',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'HP = DEF = EVA',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 62,
    Character: 'Talia',
    Slug: 'talia',
    Race: 'Fairy',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD > HP',
    Keepsake_Raid: 'SPD > HP',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 63,
    Character: 'Tasha',
    Slug: 'tasha',
    Race: 'Beast',
    Class: 'Ranger',
    Attribute: 'DEX',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'SPD = CDMG = ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 64,
    Character: 'Tohka Yatogami',
    Slug: 'tohka-yatogami',
    Race: 'Chaotic',
    Class: 'Warrior',
    Attribute: 'STR',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG%, ATK%, Demon/ Angel Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment:
      'Unlikely to rerun at this point of time, do not Erika for their dupes.',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Origin',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: 'Artifact increases alongside Ascension (Collab feature)'
  },
  {
    ID: 65,
    Character: 'Velanna',
    Slug: 'velanna',
    Race: 'Undead',
    Class: 'Warrior',
    Attribute: 'STR',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: 'CDMG = ATK',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 66,
    Character: 'Violette',
    Slug: 'violette',
    Race: 'Undead',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: '-',
    Keepsake_Raid: 'SPD, CDMG, ATK',
    Keepsake_PVP: '-',
    Keepsake_Comment:
      'Speed if using her to support/ stack her passive for the resistances. CDMG/ ATK for DPS carry.',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '05 - Niche (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '05 - Niche (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 67,
    Character: 'Vivienne',
    Slug: 'vivienne',
    Race: 'Fairy',
    Class: 'Caster',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Undead Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '07 - Specialist (PVE)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Min Rec: applies to Support Role.  Prioritise HP/ Resistances/ Tanky Keepsakes if dying.',
    Ascension_Priority: '07 - Specialist (PVE)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '05 - Niche (Raids)',
    Artifact_Comment:
      'OG gives Stun on Main/ Ult, useful for specific raid situations (Rudra, ESS), good in general for PvE. But fallen off in usage regardless.'
  },
  {
    ID: 68,
    Character: 'Weiss',
    Slug: 'weiss',
    Race: 'Undead',
    Class: 'Ranger',
    Attribute: 'INT',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: 'CDMG = ATK',
    Keepsake_PVP: 'CDMG = ATK > SPD',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Human Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '08 - Specialist (Raids)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Eternal +',
    Rec_Ascension_Investment: 'Origin+',
    Ascension_Comment: '',
    Ascension_Priority: '08 - Specialist (Raids)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '08 - Specialist (Raids)',
    Artifact_Comment: ''
  },
  {
    ID: 69,
    Character: 'Wheri',
    Slug: 'wheri',
    Race: 'Fairy',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD > HP',
    Keepsake_Raid: 'SPD > HP',
    Keepsake_PVP: 'SPD = HP',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Leg +',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment: ''
  },
  {
    ID: 70,
    Character: 'Xiaolian',
    Slug: 'xiaolian',
    Race: 'Beast',
    Class: 'Striker',
    Attribute: 'STR',
    Keepsake_PVE: 'CDMG = ATK',
    Keepsake_Raid: '-',
    Keepsake_PVP: '-',
    Keepsake_Comment: '',
    Best_Potential_Stats: 'CDMG% > ATK% > Fairy Dmg%',
    Alternative_Potential_Stats: '',
    Potential_Priority: '04 - Niche',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Origin',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment: '',
    Ascension_Priority: '04 - Niche',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Any',
    Artifact_Priority: '04 - Niche',
    Artifact_Comment: ''
  },
  {
    ID: 71,
    Character: 'Yuria',
    Slug: 'yuria',
    Race: 'Angel',
    Class: 'Supporter',
    Attribute: 'INT',
    Keepsake_PVE: 'SPD',
    Keepsake_Raid: 'SPD',
    Keepsake_PVP: 'SPD',
    Keepsake_Comment:
      'If speed tuning/ survivability walled, may use Atk or HP',
    Best_Potential_Stats: 'HP%, Resistances',
    Alternative_Potential_Stats: '',
    Potential_Priority: '11 - Meta (Overall)',
    Potential_Comment: '',
    Min_Ascension_Investment: 'Any',
    Rec_Ascension_Investment: 'Origin',
    Ascension_Comment:
      'Angel Soul - will need 14 dupes to hit Origin. Heals lacking at Min Rec. Run 2nd healer if needed. Prioritise HP/ Resistances/ Tanky Keepsakes if dying at Min Rec.',
    Ascension_Priority: '11 - Meta (Overall)',
    Min_Artifact_Investment: 'Any',
    Rec_Artifact_Investment: 'Origin',
    Artifact_Priority: '11 - Meta (Overall)',
    Artifact_Comment: ''
  }
];
